import { template as template_1b4e2117fd394e0a8119f7f8cc435c87 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1b4e2117fd394e0a8119f7f8cc435c87(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
