import { template as template_232bf9b169a74385aa71315b7a3b57a1 } from "@ember/template-compiler";
const FKText = template_232bf9b169a74385aa71315b7a3b57a1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
